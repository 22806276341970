<template>
  <div v-if="todos">
    <!-- select tasks' category -->
    <v-row>
      <v-col>
        <v-badge
          left
          :value="newTodoCounter.length"
          offset-x="10"
          offset-y="10"
          color="green"
          :content="newTodoCounter.length"
          class="mx-10"
        >
          <v-select
            :items="categories"
            @change="changeCategory"
            v-model="activeCategory"
            item-value="category"
            item-text="text"
            solo
            outlined
          >
            <template v-slot:item="{ item }">
              <v-badge
                :value="todoCounterByCategory(item)"
                :content="todoCounterByCategory(item)"
                left
                color="green"
              >
                <div>
                  {{ item.text }}
                </div>
              </v-badge>
            </template>
          </v-select>
        </v-badge>
      </v-col>
    </v-row>
    <avuka v-if="isAvuka" />
    <v-card v-if="!isAvuka" class="mx-5" elevation="2">
      <div
        class="px-2 py-2 responsive-font"
        v-for="(item, i) in events"
        :key="item._id"
        :style="`background-color:${colors[i]}`"
      >
        <v-row dense class="align-center justify-end">
          <v-col class="d-flex align-end">
            <v-checkbox
              :ripple="false"
              hide-details
              :value="item._id"
              v-model="eventsSelected"
              color="white"
              class="event-checkbox"
            />
            <span class="responsive-text-placement">
              {{ eventTypeText(item.category) }}
            </span>
            <span class="ms-1 responsive-text-placement">
              {{ time(item.createdAt) }}
            </span>
          </v-col>
          <v-col class="d-flex justify-end" v-if="!isAdmin" cols="2">
            <v-btn
              class="responsive-font px-2"
              @click="openReportDialog(item)"
              color="white"
              small
            >
              שליחת דוח מצב
            </v-btn>
          </v-col>
        </v-row>
        <span
          class="ps-8 caption responsive-text-placement"
          style="display: block"
        >
          {{ item.content }}
        </span>
        <div v-if="!isAdmin">
          <div
            v-for="(report, i) in reportsByAdmin(item.reports)"
            :key="report._id"
            class="d-flex justify-space-between align-end pt-1"
          >
            <div class="d-flex justify-start align-start">
              <v-icon
                small
                color="yellow"
                style="padding-top: 4px"
                class="pl-1"
              >
                mdi-bell
              </v-icon>
              <div style="font-size: 14px">
                {{ report.content }}
              </div>
            </div>
            <div class="text-end" v-if="item.reports.length >= 4 && i === 2">
              <v-btn
                text
                small
                class="text-decoration-underline"
                @click="showReports(item)"
              >
                עוד
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <!-- <multi-event v-if="false" :showActionBtn="true" @actionBtnClick="openReportDialog" /> -->
    <v-container v-if="!isAvuka">
      <v-row class="align-center">
        <v-col cols="5" class="done-selector">
          <!-- select if the see done/undone/all tasks -->
          <v-select
            :items="isDoneSelectInputItems"
            item-value="done"
            v-model="showDone"
            solo
            outlined
            class="ma-0 pa-0 mb-n8"
          ></v-select>
        </v-col>
        <v-col cols="5" class="font-weight-bold">
          <span v-if="filteredTasks.length">נא לסמן משימות שבוצעו:</span>
        </v-col>
        <v-col cols="2" class="pr-0">
          <v-btn
            @click="openAddTaskDialog"
            class="orange-gradient white--text rounded-lg rounded-bl-0"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- the todos -->
      <div
        class="mt-2 overflow-x-hidden"
        style="height: 55vh"
        v-if="filteredTasks.length"
      >
        <draggable
          :options="{ handle: '.drag-icon' }"
          @end="onEnd"
          v-model="localTodos"
          :element="'div'"
        >
          <todo-item
            v-for="t in localTodos"
            :key="t._id"
            :todo="t"
            @edit="edit(t)"
            :colors="colors"
        /></draggable>
      </div>
      <div class="no-todo mt-12" v-if="!filteredTasks.length">
        <span class="d-flex justify-center red--text">אין משימות</span>
      </div>
    </v-container>
    <add-todo
      v-if="showAddTaskDialog"
      v-model="showAddTaskDialog"
      :event="eventToAddToToDo"
      :todo="selectedTodoToEdit"
      @close="resetSelectedTodo"
      :category="activeCategory"
    />
    <send-report
      v-if="showSendReportDialog"
      :customChosenEvent="chosenEvent"
      v-model="showSendReportDialog"
      :category="activeCategory"
    />
    <all-reports-dialog
      v-if="showAllReports"
      v-model="showAllReports"
      :reports="reports"
    />
  </div>
  <div class="no-todo" v-else-if="!filteredTasks.length">
    <span class="d-flex justify-center red--text">אין אירוע פתוח</span>
  </div>
</template>

<script>
import Times from '@/helpers/times.js'
import TodoItem from '../components/todo/ToDo.vue'
import AddTodo from '../components/todo/AddTodo.vue'
import { categories } from '@/helpers/categoriesAndTypes'
import SendReport from '../components/reports/SendReport.vue'
import AllReportsDialog from '@/components/dialogs/AllReportsDialog.vue'
import Cookie from 'js-cookie'
import draggable from 'vuedraggable'
import Avuka from './Avuka.vue'
import { cloneDeep, orderBy } from 'lodash'
export default {
  name: 'event-user',
  components: {
    TodoItem,
    AddTodo,
    SendReport,
    AllReportsDialog,
    draggable,
    Avuka,
  },
  data() {
    return {
      eventsSelected: [],
      chosenEvent: null,
      showAllReports: false,
      reports: [],
      showSendReportDialog: false,
      showAddTaskDialog: false,
      showDone: 'all',
      activeCategory: 'headQuaters',
      isDoneSelectInputItems: [
        { text: 'הכל', done: 'all' },
        { text: 'בוצע', done: true },
        { text: 'לא בוצע', done: false },
      ],
      categories,
      selectedTodoToEdit: null,
      colors: ['#4285F4', '#DB4437', '#F4B400', '#0F9D58', '#50C7C7'],
      localTodos: [],
    }
  },
  computed: {
    isAvuka() {
      return this.activeCategory === 'avuka'
    },
    filteredTasks() {
      let out = this.todos
      if (this.showDone != 'all') {
        out = out?.filter(t => t.isChecked == this.showDone)
      }
      return out.map(t => {
        if (this.newTodoCounter.map(t => t._id).includes(t._id)) {
          t.isNew = true
        } else {
          t.isNew = false
        }
        return t
      })
    },
    todos() {
      // if (!this.isAdmin) return this.$store.getters?.event?.todos
      const eventsSelected = this.eventsSelected
      return (
        this.$store.getters?.toTodoByDepartment[this.activeCategory]?.filter(
          todo => eventsSelected.includes(todo.eventId)
        ) || []
      )
    },
    newTodoCounter() {
      return this.$store.getters.newTodoCounter
    },
    event() {
      return this.$store.getters.event
    },
    events() {
      return this.$store.getters.events
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    allTodos() {
      return this.$store.getters.allTodos
    },
    eventsSelecteda() {
      return this.events.filter(event =>
        this.eventsSelected.includes(event._id)
      )
    },
    eventToAddToToDo() {
      // if (!this.isAdmin) return this.event
      // else
      if (this.eventsSelected.length === 1)
        return this.events.find(event => event._id === this.eventsSelected[0])
      else if (this.eventsSelected.length > 1)
        return this.events.filter(event =>
          this.eventsSelected.includes(event._id)
        )
      else return this.events
    },
    items() {
      return this.$store.getters.eventTypes
    },
  },
  methods: {
    eventTypeText(eventType) {
      return this.items.find(item => item.name === eventType).text
    },
    async onEnd(todo) {
      if (todo.oldIndex === todo.newIndex) return
      const totalItems = this.localTodos.length
      const changedItems = this.localTodos.map((item, idx) => ({
        _id: item._id,
        index: totalItems - idx, // first item gets highest index
        event: item.eventId,
      }))
      this.loading = true
      await this.$store.dispatch('todo/updateIndexes', { changedItems })
      this.loading = false
    },
    reportsByAdmin(reports) {
      return reports
        .filter(r => r.createdByAdmin)
        .slice(-3)
        .reverse()
    },
    todoCounterByCategory(item) {
      return this.newTodoCounter.filter(c => c.category === item.category)
        .length
    },
    showReports(item) {
      this.reports = item.reports
      this.showAllReports = true
    },
    time(date) {
      return Times.extractTimeString(date)
    },
    openAddTaskDialog() {
      this.showAddTaskDialog = true
    },

    edit(todo) {
      this.selectedTodoToEdit = todo
      this.showAddTaskDialog = true
    },
    resetSelectedTodo() {
      this.selectedTodoToEdit = null
    },
    changeCategory(e) {
      const lastCategory = this.$route.params?.category
      this.filteredTasks.forEach(t => {
        if (t.category === lastCategory) {
          t.isNew = false
        }
      })
      this.$store.commit('counterTodo/destroyByCategory', lastCategory)
      this.$router.push('/todo/' + e)
    },
    checkIfIsInCategory(categories) {
      return Array.prototype.includes.call(
        categories.map(c => c.category),
        this.$route.params.category
      )
    },
    openReportDialog(e) {
      this.chosenEvent = e
      this.showSendReportDialog = true
    },
  },
  mounted() {
    const id = this.event ? this.event?._id : this.events[0]?._id
    if (id) {
      this.$store.commit('events/chosen', [id])
      this.eventsSelected = [id]
      this.$store.commit('event/set', this.event)
    }
    const category = this.$route.params?.category
    if (category && this.checkIfIsInCategory(categories)) {
      this.activeCategory = category
    } else {
      this.changeCategory('headQuaters')
    }
    Cookie.set('department', this.activeCategory, { path: '/' })
    this.localTodos = cloneDeep(this.filteredTasks)
    this.eventsSelected = this.events.map(e => e._id)
  },
  watch: {
    eventsSelected() {
      this.$store.commit('events/chosen', this.eventsSelected)
    },
    activeCategory(department) {
      Cookie.set('department', department, { path: '/' })
    },
    filteredTasks: {
      handler: function () {
        this.localTodos = orderBy(
          cloneDeep(this.filteredTasks),
          ['index'],
          ['desc']
        )
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss" scoped>
@media (max-width: 399px) {
  .responsive-font {
    font-size: 13px !important;
  }
  .responsive-text-placement {
    padding-bottom: 3px;
  }
}
@media (max-width: 359px) {
  .responsive-font {
    font-size: 11px !important;
  }
}
.no-todo {
  margin: 20% auto !important;
}
::v-deep .event-checkbox.v-input--checkbox {
  margin: 0 !important;
}
::v-deep .event-checkbox .v-input--selection-controls__ripple {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
